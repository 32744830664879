<template>
    <div class="page-box">
        <div class="page-title flex-center between-box">
            <span>当前位置：项目管理 > <b>项目列表</b></span>
            <div class="flex-center" @click="back">
                <i class="iconfont iconjiantou-copy-copy"></i>返回
            </div>
        </div>
        <div class="project-info">
            <div class="project-condition">
                <div class="flex-center title-tab">
                    <el-button :class="{ active: table === 1 }" @click="table = 1"
                    >(表X-1)赞助汇总表+结算
                    </el-button>
                    <el-button :class="{ active: table === 3 }" @click="table = 3"
                    >乳腺病学会汇总表
                    </el-button>
                    <el-button :class="{ active: table === 4 }" @click="table = 4"
                    >癌症学会汇总表
                    </el-button>
                    <el-button :class="{ active: table === 2 }" @click="table = 2"
                    >(表X-2)赞助汇总表+结算
                    </el-button>
                    <router-link class="settlement" :to="'/home/settlement?id=' + id+'&uid='+uid">
                        <el-button>专项结项结算单</el-button>
                    </router-link>
                    <router-link class="settlement" :to="'/home/sponsorReward?id=' + id+'&uid='+uid">
                        <el-button>赞助回报汇总</el-button>
                    </router-link>
                </div>
                <div class="flex-center"></div>
            </div>
            <div class="project-list" v-show="table === 1">
                <div>
                    <h3>
                        赞助汇总表
                        <div>
                            <el-button type="primary" @click="downFile()">导出</el-button>
                            <el-button class="left-20" type="success" @click="getShare()"
                            >分享
                            </el-button>
                        </div>
                    </h3>
                    <el-table
                            :data="tableData"
                            header-row-class-name="table-header"
                            style="width: 100%"
                            height="650"
                    >
                        <el-table-column fixed label="序号" align="center" width="100">
                            <template slot-scope="scope">
                                <div class="table-item">
                                    {{ scope.$index + 1 }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column fixed label="招商学会" width="150">
                            <template slot-scope="scope">
                                <div class="table-item">
                                    {{ scope.row.society }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column fixed label="企业名称" width="150">
                            <template slot-scope="scope">
                                <div class="table-item">
                                    {{ !scope.row.sid ? "--" : scope.row.name }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column fixed label="企业备注" width="150">
                            <template slot-scope="scope">
                                <div class="table-item">
                                    {{ !scope.row.sid ? "--" : scope.row.corporate_name_remarks }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column fixed label="企业联系人" width="100">
                            <template slot-scope="scope">
                                <div class="table-item">
                                    {{ !scope.row.sid ? "--" : scope.row.contacts }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column fixed label="招商函发出日期" width="180">
                            <template slot-scope="scope">
                                <div class="table-item">
                                    {{ !scope.row.sid ? "--" : scope.row.dissemination_date }}
                                </div>
                            </template>
                        </el-table-column>

                        <el-table-column :label="`${$getTitleByType('会议', cid)}基金`">
                            <el-table-column width="110" label="拟赞助金额（万元）">
                                <template slot-scope="scope">
                                    <div class="table-item">
                                        {{ !scope.row.sid ? "--" : scope.row.sponsorship_metting }}
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column width="110" label="协议签订金额（万元）">
                                <template slot-scope="scope">
                                    <div class="table-item">
                                        {{ !scope.row.sid ? "--" : scope.row.agreement_metting }}
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column width="110" label="协议签订日期">
                                <template slot-scope="scope">
                                    <div class="table-item">
                                        {{ !scope.row.sid ? "--" : scope.row.agreement_date }}
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column width="110" label="到款金额（万元）">
                                <template slot-scope="scope">
                                    <div class="table-item">
                                        {{ !scope.row.sid ? "--" : scope.row.received_metting }}
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column width="110" label="到款日期">
                                <template slot-scope="scope">
                                    <div class="table-item">
                                        {{ !scope.row.sid ? "--" : scope.row.received_date }}
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column width="130" label="管理费+税费（万元）">
                                <template slot-scope="scope">
                                    <div class="table-item">
                                        {{ !scope.row.sid ? "--" : scope.row.management_amount }}
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column width="130" :label="`${$getTitleByType('会议', cid)}可用金额 （万元）`">
                                <template slot-scope="scope">
                                    <div class="table-item">
                                        {{ scope.row.meet_run }}
                                    </div>
                                </template>
                            </el-table-column>
                            <!--                            <el-table-column-->
                            <!--                                width="150"-->
                            <!--                                label="会议管理费+税金优惠申请状态 "-->
                            <!--                            >-->
                            <!--                              <template slot-scope="scope">-->
                            <!--                                <div class="table-item">-->
                            <!--                                  {{ scope.row.sid ? (scope.row.management_amount_state | state) : '&#45;&#45;' }}-->
                            <!--                                </div>-->
                            <!--                              </template>-->
                            <!--                            </el-table-column>-->
                        </el-table-column>

                        <!--            <el-table-column width="180" fixed="right" label="操作">-->
                        <!--              <template>-->
                        <!--                <div class="table-operate">-->
                        <!--                  <el-button type="text" size="small">编辑</el-button>-->
                        <!--                </div>-->
                        <!--              </template>-->
                        <!--            </el-table-column>-->
                    </el-table>
                    <el-table
                            :data="total"
                            class="table-total"
                            :show-header="false"
                            :span-method="objectSpanMethod"
                            style="width: 100%; background: #f2f2f2"
                    >
                        <el-table-column width="100">
                            <template>
                                <div class="table-item">总计</div>
                            </template>
                        </el-table-column>
                        <el-table-column width="300">
                            <template slot-scope="scope">
                                <div class="table-item">
                                    {{ scope.row.name2 }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column>
                            <template slot-scope="scope">
                                <div class="table-item">
                                    <span>{{ scope.row.num2 }}</span>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div>
                    <h3>供应商汇总表</h3>
                    <el-table
                            :data="tableData2"
                            header-row-class-name="table-header"
                            style="width: 100%"
                            height="550"
                    >
                        <el-table-column
                                fixed
                                label="序号"
                                type="index"
                                align="center"
                                width="100"
                        >
                        </el-table-column>
                        <el-table-column fixed label="学会名称" width="150">
                            <template slot-scope="scope">
                                <div class="table-item">
                                    {{ scope.row.society }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column fixed label="供应商名称" width="150">
                            <template slot-scope="scope">
                                <div class="table-item">
                                    {{ scope.row.if_ins ? '--' : scope.row.name }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column fixed label="供应商备注">
                            <template slot-scope="scope">
                                <div class="table-item">
                                    {{ scope.row.if_ins ? '--' : scope.row.corporate_name_remarks }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column fixed width="100" label="联系人">
                            <template slot-scope="scope">
                                <div class="table-item">
                                    {{ scope.row.if_ins ? '--' : scope.row.contacts }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column width="110" label="合同总金额（万元）">
                            <template slot-scope="scope">
                                <div class="table-item">
                                    {{ scope.row.if_ins ? '--' : scope.row.contract_amount }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="合同状态" width="110">
                            <template slot-scope="scope">
                                <div class="table-item">
                                    {{ scope.row.if_ins ? '--' : (scope.row.contract_state == 0 ? "未回" : "已回") }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column width="150" label="合同已回时间">
                            <template slot-scope="scope">
                                <div class="table-item">
                                    {{ scope.row.if_ins ? '--' : scope.row.contract_time }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column width="150" label="付款项目">
                            <template slot-scope="scope">
                                <div class="table-item">
                                    {{ scope.row.payment_project ?  scope.row.payment_project  : '--' }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column width="110" label="付款总金额（万元）">
                            <template slot-scope="scope">
                                <div class="table-item">
                                    {{ scope.row.payment_meeting }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="发票提交时间">
                            <template slot-scope="scope">
                                <div class="table-item">
                                    <div v-if="scope.row.if_ins && scope.row.check.length === 0">--</div>
                                    <div v-else>
                                        <div v-for="(item,index) in scope.row.check" :key="index">
                                            {{ item.received_date }}
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                        <!--<el-table-column fixed="right" width="150" label="操作">-->
                        <!--<template>-->
                        <!--<div class="table-operate">-->
                        <!--<el-button type="text" size="small">编辑</el-button>-->
                        <!--</div>-->
                        <!--</template>-->
                        <!--</el-table-column>-->
                    </el-table>
                    <el-table
                            :data="total3"
                            class="table-total"
                            :show-header="false"
                            style="width: 100%; background: #f2f2f2"
                    >
                        <el-table-column width="340">
                            <template slot-scope="scope">
                                <div class="table-item">
                                    {{ scope.row.name }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column>
                            <template slot-scope="scope">
                                <div class="table-item">
                                    {{ scope.row.num }}
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div class="remerk-container">
                    <el-input
                            class="remerk-content"
                            type="textarea"
                            resize="none"
                            :rows="5"
                            :disabled="!isAdmin"
                            v-model="table_remark"
                            placeholder="请输入备注"
                    ></el-input>
                    <el-button type="primary" @click="setRemarks" v-if="isAdmin">保存</el-button>
                </div>
            </div>

            <div class="project-list" v-show="table >2">
                <div>
                    <h3>
                        赞助汇总表
                    </h3>
                    <el-table
                            :data="tableData"
                            header-row-class-name="table-header"
                            style="width: 100%"
                            height="650"
                    >
                        <el-table-column fixed label="序号" align="center" width="100">
                            <template slot-scope="scope">
                                <div class="table-item">
                                    {{ scope.$index + 1 }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column fixed label="招商学会" width="150">
                            <template slot-scope="scope">
                                <div class="table-item">
                                    {{ scope.row.society }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column fixed label="企业名称" width="150">
                            <template slot-scope="scope">
                                <div class="table-item">
                                    {{ !scope.row.sid ? "--" : scope.row.name }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column fixed label="企业备注" width="150">
                            <template slot-scope="scope">
                                <div class="table-item">
                                    {{ !scope.row.sid ? "--" : scope.row.corporate_name_remarks }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column fixed label="企业联系人" width="100">
                            <template slot-scope="scope">
                                <div class="table-item">
                                    {{ !scope.row.sid ? "--" : scope.row.contacts }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column fixed label="招商函发出日期" width="180">
                            <template slot-scope="scope">
                                <div class="table-item">
                                    {{ !scope.row.sid ? "--" : scope.row.dissemination_date }}
                                </div>
                            </template>
                        </el-table-column>

                        <el-table-column :label="`${$getTitleByType('会议', cid)}基金`">
                            <el-table-column width="110" label="拟赞助金额（万元）">
                                <template slot-scope="scope">
                                    <div class="table-item">
                                        {{ !scope.row.sid ? "--" : scope.row.sponsorship_metting }}
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column width="110" label="协议签订金额（万元）">
                                <template slot-scope="scope">
                                    <div class="table-item">
                                        {{ !scope.row.sid ? "--" : scope.row.agreement_metting }}
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column width="110" label="协议签订日期">
                                <template slot-scope="scope">
                                    <div class="table-item">
                                        {{ !scope.row.sid ? "--" : scope.row.agreement_date }}
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column width="110" label="到款金额（万元）">
                                <template slot-scope="scope">
                                    <div class="table-item">
                                        {{ !scope.row.sid ? "--" : scope.row.received_metting }}
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column width="110" label="到款日期">
                                <template slot-scope="scope">
                                    <div class="table-item">
                                        {{ !scope.row.sid ? "--" : scope.row.received_date }}
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column width="130" label="管理费+税费（万元）">
                                <template slot-scope="scope">
                                    <div class="table-item">
                                        {{ !scope.row.sid ? "--" : scope.row.management_amount }}
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column width="130" :label="`${$getTitleByType('会议', cid)}可用金额 （万元）`">
                                <template slot-scope="scope">
                                    <div class="table-item">
                                        {{ scope.row.meet_run }}
                                    </div>
                                </template>
                            </el-table-column>
                            <!--                            <el-table-column-->
                            <!--                                width="150"-->
                            <!--                                label="会议管理费+税金优惠申请状态 "-->
                            <!--                            >-->
                            <!--                              <template slot-scope="scope">-->
                            <!--                                <div class="table-item">-->
                            <!--                                  {{ scope.row.sid ? (scope.row.management_amount_state | state) : '&#45;&#45;' }}-->
                            <!--                                </div>-->
                            <!--                              </template>-->
                            <!--                            </el-table-column>-->
                        </el-table-column>

                        <!--            <el-table-column width="180" fixed="right" label="操作">-->
                        <!--              <template>-->
                        <!--                <div class="table-operate">-->
                        <!--                  <el-button type="text" size="small">编辑</el-button>-->
                        <!--                </div>-->
                        <!--              </template>-->
                        <!--            </el-table-column>-->
                    </el-table>
                    <el-table
                            :data="total"
                            class="table-total"
                            :show-header="false"
                            :span-method="objectSpanMethod"
                            style="width: 100%; background: #f2f2f2"
                    >
                        <el-table-column width="100">
                            <template>
                                <div class="table-item">总计</div>
                            </template>
                        </el-table-column>
                        <el-table-column width="300">
                            <template slot-scope="scope">
                                <div class="table-item">
                                    {{ scope.row.name2 }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column>
                            <template slot-scope="scope">
                                <div class="table-item">
                                    <span>{{ scope.row.num2 }}</span>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div>
                    <h3>供应商汇总表</h3>
                    <el-table
                            :data="tableData2"
                            header-row-class-name="table-header"
                            style="width: 100%"
                            height="550"
                    >
                        <el-table-column
                                label="序号"
                                type="index"
                                align="center"
                                width="100"
                        >
                        </el-table-column>
                        <el-table-column  label="学会名称">
                            <template slot-scope="scope">
                                <div class="table-item">
                                    {{ scope.row.society }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column  label="供应商名称">
                            <template slot-scope="scope">
                                <div class="table-item">
                                    {{ scope.row.if_ins ? '--' : scope.row.name }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column  label="供应商备注">
                            <template slot-scope="scope">
                                <div class="table-item">
                                    {{ scope.row.if_ins ? '--' : scope.row.corporate_name_remarks }}
                                </div>
                            </template>
                        </el-table-column>
                        <!--<el-table-column fixed width="100" label="联系人">-->
                            <!--<template slot-scope="scope">-->
                                <!--<div class="table-item">-->
                                    <!--{{ scope.row.contacts }}-->
                                <!--</div>-->
                            <!--</template>-->
                        <!--</el-table-column>-->
                        <!--<el-table-column width="110" label="合同总金额（万元）">-->
                            <!--<template slot-scope="scope">-->
                                <!--<div class="table-item">-->
                                    <!--{{ scope.row.contract_amount }}-->
                                <!--</div>-->
                            <!--</template>-->
                        <!--</el-table-column>-->
                        <!--<el-table-column label="合同状态" width="110">-->
                            <!--<template slot-scope="scope">-->
                                <!--<div class="table-item">-->
                                    <!--{{ scope.row.contract_state == 0 ? "未回" : "已回" }}-->
                                <!--</div>-->
                            <!--</template>-->
                        <!--</el-table-column>-->
                        <!--<el-table-column width="150" label="合同已回时间">-->
                            <!--<template slot-scope="scope">-->
                                <!--<div class="table-item">-->
                                    <!--{{ scope.row.contract_time }}-->
                                <!--</div>-->
                            <!--</template>-->
                        <!--</el-table-column>-->
                        <el-table-column label="付款项目">
                            <template slot-scope="scope">
                                <div class="table-item">
                                    {{ scope.row.payment_project ?  scope.row.payment_project  : '--'}}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="付款总金额（万元）"  width="200">
                            <template slot-scope="scope">
                                <div class="table-item">
                                    {{ scope.row.payment_meeting }}
                                </div>
                            </template>
                        </el-table-column>
                        <!--<el-table-column label="发票提交时间">-->
                            <!--<template slot-scope="scope">-->
                                <!--<div class="table-item">-->
                                    <!--{{ scope.row.invoice_time }}-->
                                <!--</div>-->
                            <!--</template>-->
                        <!--</el-table-column>-->
                        <!--<el-table-column fixed="right" width="150" label="操作">-->
                        <!--<template>-->
                        <!--<div class="table-operate">-->
                        <!--<el-button type="text" size="small">编辑</el-button>-->
                        <!--</div>-->
                        <!--</template>-->
                        <!--</el-table-column>-->
                    </el-table>
                    <el-table
                            :data="total3"
                            class="table-total"
                            :show-header="false"
                            style="width: 100%; background: #f2f2f2"
                    >
                        <el-table-column width="340">
                            <template slot-scope="scope">
                                <div class="table-item">
                                    {{ scope.row.name }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column>
                            <template slot-scope="scope">
                                <div class="table-item">
                                    {{ scope.row.num }}
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>

            <div class="project-list" v-show="table === 2">
                <div>
                    <h3>
                        赞助汇总表
                        <div>
                            <el-button
                                    type="primary"
                                    v-if="isAdmin"
                                    @click="setFl(0, id, p_rate.rate, p_rate.rate_state)"
                            >设置项目管理费率
                            </el-button>
                            <el-button type="primary"  v-if="isAdmin"  @click="showDialog(false)"
                            >新增赞助
                            </el-button>
                        </div>
                    </h3>
                    <el-table
                            :data="tableData"
                            header-row-class-name="table-header"
                            style="width: 100%"
                            height="650"
                    >
                        <el-table-column fixed label="序号" align="center" width="100">
                            <template slot-scope="scope">
                                <div class="table-item">
                                    {{ scope.$index + 1 }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column fixed label="所属学会" width="150">
                            <template slot-scope="scope">
                                <div class="table-item">
                                    {{ scope.row.society }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column fixed label="企业名称" width="150">
                            <template slot-scope="scope">
                                <div class="table-item">
                                    {{ !scope.row.zid ? "--" : scope.row.name }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column fixed label="企业备注" width="150">
                            <template slot-scope="scope">
                                <div class="table-item">
                                    {{ !scope.row.sid ? "--" : scope.row.corporate_name_remarks }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column fixed label="企业联系人" width="100">
                            <template slot-scope="scope">
                                <div class="table-item">
                                    {{ !scope.row.zid ? "--" : scope.row.contacts }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column fixed label="项目类别" width="180">
                            <template slot-scope="scope">
                                <div class="table-item">
                                    {{ !scope.row.zid ? "--"  : (scope.row.is_tuikuan === 1 ? '退款' :  scope.row.categories === 1 ? "赞助" : "捐赠") }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column fixed label="招商资料日期" width="180">
                            <template slot-scope="scope">
                                <div class="table-item">
                                    {{ !scope.row.zid ? "--" : scope.row.dissemination_date }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column :label="`${$getTitleByType('会议', cid)}基金`">
                            <el-table-column width="110"  :label="`拟定${$getTitleByType('会议', cid)}基金金额（万元）`" >
                                <template slot-scope="scope">
                                    <div class="table-item">
                                        {{ !scope.row.zid ? "--" : scope.row.sponsorship_metting }}
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column width="110" :label="`${$getTitleByType('会议', cid)}基金签订金额（万元）`">
                                <template slot-scope="scope">
                                    <div class="table-item">
                                        {{ !scope.row.zid ? "--" : scope.row.agreement_metting }}
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column width="110" :label="`${$getTitleByType('会议', cid)}基金签订日期`">
                                <template slot-scope="scope">
                                    <div class="table-item">
                                        {{ !scope.row.zid ? "--" : scope.row.agreement_date }}
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column width="110" :label="`${$getTitleByType('会议', cid)}基金到款金额（万元）`">
                                <template slot-scope="scope">
                                    <div class="table-item">
                                        {{ !scope.row.zid ? "--" : scope.row.received_metting }}
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column width="110" :label="`${$getTitleByType('会议', cid)}基金到款日期`">
                                <template slot-scope="scope">
                                    <div class="table-item">
                                        {{ !scope.row.zid ? "--" : scope.row.received_date }}
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column width="130" :label="`${$getTitleByType('会议', cid)}基金管理费+税费（万元）`">
                                <template slot-scope="scope">
                                    <div class="table-item">
                                        {{ !scope.row.zid ? "--" : scope.row.management_amount }}
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column width="130" :label="`${$getTitleByType('会议', cid)}基金可用金额（万元）`">
                                <template slot-scope="scope">
                                    <div class="table-item" style="color: #5daf34">
                                        {{ scope.row.meet_run }}
                                    </div>
                                </template>
                            </el-table-column>
                            <!--                            <el-table-column-->
                            <!--                                width="150"-->
                            <!--                                label="会议管理费+税金优惠申请状态 "-->
                            <!--                            >-->
                            <!--                              <template slot-scope="scope">-->
                            <!--                                <div class="table-item">-->
                            <!--                                  {{ scope.row.sid ? (scope.row.management_amount_state | state) : '&#45;&#45;' }}-->
                            <!--                                </div>-->
                            <!--                              </template>-->
                            <!--                            </el-table-column>-->
                        </el-table-column>

                        <el-table-column label="专项基金">
                            <el-table-column width="110" label="拟定专项基金金额（万元）">
                                <template slot-scope="scope">
                                    <div class="table-item">
                                        {{ !scope.row.zid ? "--" : scope.row.sponsorship_self }}
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column width="110" label="专项基金签订金额（万元）">
                                <template slot-scope="scope">
                                    <div class="table-item">
                                        {{ !scope.row.zid ? "--" : scope.row.agreement_self }}
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column width="110" label="专项基金签订日期">
                                <template slot-scope="scope">
                                    <div class="table-item">
                                        {{ !scope.row.sid ? "--" : scope.row.agreement_date }}
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column width="110" label="专项基金到款金额 （万元）">
                                <template slot-scope="scope">
                                    <div class="table-item">
                                        {{ !scope.row.zid ? "--" : scope.row.received_self }}
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column width="110" label="专项基金到款日期">
                                <template slot-scope="scope">
                                    <div class="table-item">
                                        {{ !scope.row.zid ? "--" : scope.row.received_date }}
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column width="130" label="专项基金管理费+税费（万元）">
                                <template slot-scope="scope">
                                    <div class="table-item">
                                        {{ !scope.row.zid ? "--" : scope.row.selfuse_amount * 1 }}
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column width="130" label="专项基金可用金额（万元）">
                                <template slot-scope="scope">
                                    <div class="table-item" style="color: #df5000">
                                        {{ !scope.row.zid ? "--" : scope.row.self_run * 1 }}
                                    </div>
                                </template>
                            </el-table-column>
                            <!--              <el-table-column-->
                            <!--                  width="150"-->
                            <!--                  label="自用管理费+税金优惠申请状态"-->
                            <!--              >-->
                            <!--                <template slot-scope="scope">-->
                            <!--                  <div class="table-item">-->
                            <!--                    {{ scope.row.sid ? (scope.row.selfuse_amount_state | state) : '&#45;&#45;' }}-->
                            <!--                  </div>-->
                            <!--                </template>-->
                            <!--              </el-table-column>-->
                        </el-table-column>

                        <el-table-column label="协议（总金额）">
                            <el-table-column width="130" label="拟定协议金额（万元）">
                                <template slot-scope="scope">
                                    <div class="table-item">
                                        {{ !scope.row.zid ? "--" : scope.row.sponsorship_amount }}
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column width="110" label="协议签订金额（万元）">
                                <template slot-scope="scope">
                                    <div class="table-item">
                                        {{ !scope.row.zid ? "--" : scope.row.agreement_amount }}
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column width="180" label="协议签订日期">
                                <template slot-scope="scope">
                                    <div class="table-item">
                                        {{ !scope.row.sid ? "--" : scope.row.agreement_date }}
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column width="110" label="协议到款金额（万元）">
                                <template slot-scope="scope">
                                    <div class="table-item">
                                        {{ !scope.row.zid ? "--" : scope.row.received_amount }}
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column width="110" label="协议到款日期">
                                <template slot-scope="scope">
                                    <div class="table-item">
                                        {{ !scope.row.zid ? "--" : scope.row.received_date }}
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column label="协议管理费+税费（万元）" width="110px">
                                <template slot-scope="scope">
                                    <div class="table-item">
                                        {{
                                        !scope.row.zid ? "--" : scope.row.agreement_run_amount * 1
                                        }}
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column label="协议可用金额（万元）" width="110px">
                                <template slot-scope="scope">
                                    <div class="table-item" style="color: #3b77e7">
                                        {{ scope.row.agreement_run * 1 }}
                                    </div>
                                </template>
                            </el-table-column>

                            <!--              <el-table-column-->
                            <!--                  label="协议状态"-->
                            <!--                  width="100">-->
                            <!--                <template slot-scope="scope">-->
                            <!--                  <div class="table-item">-->
                            <!--                    {{ scope.row.sid ? (scope.row.agreement_state === 0 ? '未回' : '已回') : '&#45;&#45;' }}-->
                            <!--                  </div>-->
                            <!--                </template>-->
                            <!--              </el-table-column>-->
                        </el-table-column>

                        <el-table-column label="备注">
                            <template slot-scope="scope">
                                <div class="table-item">
                                    {{ scope.row.amount_desc }}
                                </div>
                            </template>
                        </el-table-column>

                        <el-table-column label="赞助协议状态">
                            <template slot-scope="scope">
                                <div class="table-item">
                                    {{ scope.row.xid ? (scope.row.agreement_state == 1 ? "已回" : "未回"): '--'  }}
                                </div>
                            </template>
                        </el-table-column>

                        <el-table-column label="到款状态" width="200px">
                            <template slot-scope="scope">
                                <div class="table-item">
                                    <div v-for="(item, index) in scope.row.received" :key="index">
                                        ({{ item.received_date }})
                                        <br/>
                                        金额：{{ item.received_money * 1 }}
                                    </div>
                                </div>
                            </template>
                        </el-table-column>

                        <el-table-column label="发票状态" width="200px">
                            <template slot-scope="scope">
                                <div class="table-item">
                                    <div v-for="(item, index) in scope.row.invoice" :key="index">
                                        ({{ item.received_date }})<br/>金额：{{
                                        item.received_money * 1
                                        }}
                                    </div>
                                </div>
                            </template>
                        </el-table-column>

                        <el-table-column width="180" fixed="right" label="操作">
                            <template slot-scope="scope">
                                <div class="table-operate">
                                    <el-button
                                            type="text"
                                            size="small"
                                            @click="setSponsorState(scope.row.id)"
                                            v-if="scope.row.agreement_state === 0 && scope.row.xid  && isAdmin"
                                    >协议已回
                                    </el-button>
                                    <el-button
                                            type="text"
                                            size="small"
                                            @click="
                      setFl(
                        1,
                        scope.row.id,
                        scope.row.meeting_rate ? scope.row.meeting_rate : 0.15,
                        scope.row.management_amount_state
                      )
                    "
                                            v-if="scope.row.zid && isAdmin && !scope.row.is_tuikuan"
                                    >{{$getTitleByType('会议', cid)}}管理费率
                                    </el-button>
                                    <el-button
                                            type="text"
                                            size="small"
                                            @click="
                      setFl(
                        2,
                        scope.row.id,
                        scope.row.self_rate,
                        scope.row.selfuse_amount_state
                      )
                    "
                                            v-if="scope.row.zid && isAdmin && !scope.row.is_tuikuan"
                                    >专项管理费率
                                    </el-button>
                                    <el-button
                                            type="text"
                                            size="small"
                                            @click="revokeItem(scope.row.id)"
                                            v-if="scope.row.zid && isCWLD"
                                    >撤销
                                    </el-button>
                                    <el-button
                                            type="text"
                                            size="small"
                                            @click="showDialog(scope.row)"
                                            v-if="!scope.row.zid && isAdmin"
                                    >编辑
                                    </el-button>
                                    <el-button
                                            type="text"
                                            size="small"
                                            style="color: #f03737"
                                            @click="deleteItem(scope.row.id)"
                                            v-if="!scope.row.zid && isAdmin"
                                    >删除
                                    </el-button>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-table
                            :data="total"
                            class="table-total"
                            :show-header="false"
                            :span-method="objectSpanMethod"
                            style="width: 100%; background: #f2f2f2"
                    >
                        <el-table-column width="100">
                            <template>
                                <div class="table-item">总计</div>
                            </template>
                        </el-table-column>
                        <el-table-column>
                            <template slot-scope="scope">
                                <div class="table-item" style="color: #3b77e7">
                                    {{ scope.row.name }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column>
                            <template slot-scope="scope">
                                <div class="table-item" style="color: #3b77e7">
                                    <span>{{ scope.row.num }}</span>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column>
                            <template slot-scope="scope">
                                <div class="table-item" style="color: #5daf34">
                                    {{ scope.row.name2 }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column>
                            <template slot-scope="scope">
                                <div class="table-item" style="color: #5daf34">
                                    <span>{{ scope.row.num2 }}</span>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column>
                            <template slot-scope="scope">
                                <div class="table-item" style="color: #df5000">
                                    {{ scope.row.name3 }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column>
                            <template slot-scope="scope">
                                <div class="table-item" style="color: #df5000">
                                    <span>{{ scope.row.num3 }}</span>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div>
                    <h3>
                        供应商汇总表
                        <div>
                            <el-button type="primary"  v-if="isAdmin"  @click="showCompanyDialog(false)"
                            >新增供应商
                            </el-button>
                        </div>
                    </h3>
                    <el-table
                            :data="tableData2"
                            header-row-class-name="table-header"
                            style="width: 100%"
                            height="550"
                    >
                        <el-table-column
                                fixed
                                label="序号"
                                type="index"
                                align="center"
                                width="100"
                        >
                        </el-table-column>
                        <el-table-column fixed label="学会名称" width="150">
                            <template slot-scope="scope">
                                <div class="table-item">
                                    {{ scope.row.society }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column fixed label="供应商名称" width="150">
                            <template slot-scope="scope">
                                <div class="table-item">
                                    {{ !scope.row.if_ins && scope.row.cid ? scope.row.name  : '--'}}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column fixed  label="供应商备注">
                            <template slot-scope="scope">
                                <div class="table-item">
                                    {{  !scope.row.if_ins && scope.row.cid ? scope.row.corporate_name_remarks  : '--' }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column fixed width="100" label="联系人">
                            <template slot-scope="scope">
                                <div class="table-item">
                                    {{ !scope.row.if_ins && scope.row.cid ? scope.row.contacts : '--' }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column width="110" label="合同总金额（万元）">
                            <template slot-scope="scope">
                                <div class="table-item">
                                    {{ !scope.row.if_ins && scope.row.cid ? scope.row.contract_amount : '--' }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column width="110" label="会议金额（万元）">
                        <template slot-scope="scope">
                        <div class="table-item">
                        {{ !scope.row.if_ins && scope.row.cid ?  scope.row.contract_meeting : '--' }}
                        </div>
                        </template>
                        </el-table-column>
                        <el-table-column width="110" label="专项金额（万元）">
                        <template slot-scope="scope">
                        <div class="table-item">
                        {{ !scope.row.if_ins && scope.row.cid ?  scope.row.contract_self : '--' }}
                        </div>
                        </template>
                        </el-table-column>
                        <el-table-column label="合同状态" width="110">
                            <template slot-scope="scope">
                                <div class="table-item">
                                    {{ !scope.row.if_ins && scope.row.cid ? ( scope.row.contract_state == 0 ? "未回" : "已回") : '--' }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column width="150" label="合同已回时间">
                            <template slot-scope="scope">
                                <div class="table-item">
                                    {{ !scope.row.if_ins && scope.row.cid ?  scope.row.contract_time : '--'}}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column width="150" label="付款项目">
                            <template slot-scope="scope">
                                <div class="table-item">
                                    {{ scope.row.payment_project ?  scope.row.payment_project  : '--'}}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column width="110" label="付款总金额（万元）">
                            <template slot-scope="scope">
                                <div class="table-item">
                                    {{ scope.row.payment_amount }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column width="110" :label="`${$getTitleByType('会议', cid)}金额（万元）`">
                            <template slot-scope="scope">
                                <div class="table-item">
                                    {{ scope.row.payment_meeting }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column width="110" label="专项金额（万元）">
                            <template slot-scope="scope">
                                <div class="table-item">
                                    {{ scope.row.payment_self }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column width="180" label="发票提交时间">
                            <template slot-scope="scope">
                                <div class="table-item" v-if="!scope.row.if_ins && scope.row.cid">
                                    <div v-if="scope.row.check.length > 0 ||  !isAdmin">
                                        <div v-for="(item,index) in scope.row.check" :key="index">
                                            {{ item.received_date }}
                                        </div>

                                    </div>
                                    <!--<div-->
                                            <!--class="date"-->
                                            <!--@click="setInvoiceTime(scope.row.id)"-->
                                            <!--v-else-->
                                    <!--&gt;-->
                                        <!--选择时间-->
                                    <!--</div>-->
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column width="110" label="备注">
                            <template slot-scope="scope">
                                <div class="table-item">
                                    {{ scope.row.if_ins ? scope.row.remark : '--' }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column fixed="right" width="150" label="操作">
                            <template slot-scope="scope">
                                <div class="table-operate">
                                    <el-button
                                            type="text"
                                            size="small"
                                            @click="setContractState(scope.row.id)"
                                            v-if="!scope.row.if_ins && scope.row.cid && scope.row.contract_state === 0 && isAdmin"
                                    >合同已回
                                    </el-button>
                                    <el-button
                                            type="text"
                                            size="small"
                                            @click="revokeXYItem(scope.row.id)"
                                            v-if="!scope.row.if_ins && isCWLD"
                                    >撤销
                                    </el-button>
                                    <el-button
                                            type="text"
                                            size="small"
                                            @click="showCompanyDialog(scope.row)"
                                            v-if="scope.row.if_ins && isAdmin && scope.row.is_end !==1"
                                    >编辑
                                    </el-button>
                                    <el-button
                                            type="text"
                                            size="small"
                                            style="color: #f03737"
                                            @click="deleteCompanyItem(scope.row.id)"
                                            v-if="scope.row.if_ins && isAdmin && scope.row.is_end !==1"
                                    >删除
                                    </el-button>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-table
                            :data="total2"
                            class="table-total"
                            :show-header="false"
                            style="width: 100%; background: #f2f2f2"
                    >
                        <el-table-column width="340">
                            <template slot-scope="scope">
                                <div class="table-item" :style="'color:' + scope.row.color">
                                    {{ scope.row.name }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column>
                            <template slot-scope="scope">
                                <div class="table-item" :style="'color:' + scope.row.color">
                                    {{ scope.row.num }}
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
        </div>
        <el-dialog
                :visible="editZanzhu"
                :close-on-click-modal="false"
                width="550px"
                center
                :before-close="closeDialog"
        >
      <span slot="title" class="dialog-title">{{
          setItem ? "修改赞助" : "新增赞助"
          }}</span>
            <div class="editForm">
                <div class="dialog-container flex-center">
                    <div>学会名称</div>
                    <el-select v-model="zzInfo.num1"  placeholder="请选择所属学会">
                        <el-option
                            v-for="item in societyOptions"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </div>
                <div class="dialog-container flex-center">
                    <div>可用{{$getTitleByType('会议', cid)}}基金（{{$getTitleByType('会议', cid)}}部分）</div>
                    <el-input v-model="zzInfo.num2" placeholder="请输入"></el-input>
                    <span>万元</span>
                </div>
                <div class="dialog-container flex-center active">
                    <div>备注</div>
                    <el-input
                        ref="input"
                            type="textarea"
                            resize="none"
                            :rows="5"
                            v-model="zzInfo.num3"
                            :placeholder="`请输入可用${$getTitleByType('会议', cid)}基金`"
                    ></el-input>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">取消</el-button>
        <el-button type="primary" @click="zzSubmit" :disabled="requestState"
        >确定</el-button
        >
      </span>
        </el-dialog>
        <el-dialog
                :visible="editCompany"
                :close-on-click-modal="false"
                width="550px"
                center
                :before-close="closeDialog"
        >
      <span slot="title" class="dialog-title">{{
          setItem ? "修改供应商" : "新增供应商"
          }}</span>
            <div class="editForm">
                <div class="dialog-container flex-center">
                    <div>学会名称</div>
                    <el-select v-model="cInfo.num1"  placeholder="请选择所属学会">
                        <el-option
                                v-for="item in societyOptions"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </div>
                <div class="dialog-container flex-center">
                    <div>付款项目</div>
                    <el-input v-model="cInfo.num4" placeholder="请输入"></el-input>
                </div>
                <div class="dialog-container flex-center">
                    <div>付款总金额</div>
                    <el-input v-model="cInfo.num2" placeholder="请输入"></el-input>
                    <span>万元</span>
                </div>
                <div class="dialog-container flex-center active">
                    <div>备注</div>
                    <el-input
                            type="textarea"
                            resize="none"
                            :rows="5"
                            v-model="cInfo.num3"
                            placeholder="请输入"
                    ></el-input>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">取消</el-button>
        <el-button type="primary" @click="gySubmit" :disabled="requestState"
        >确定</el-button
        >
      </span>
        </el-dialog>
        <el-dialog
                :visible="editHtTime"
                :close-on-click-modal="false"
                width="500px"
                center
                :before-close="closeDialog"
        >
            <span slot="title" class="dialog-title">设置发票时间</span>
            <div class="editFlForm">
                <el-date-picker
                        v-model="invoice_time"
                        type="datetime"
                        placeholder="选择日期"
                >
                </el-date-picker>
            </div>
            <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="setInvoiceDate">确定</el-button>
        <el-button @click="closeDialog">取消</el-button>
      </span>
        </el-dialog>
        <el-dialog
                :visible="showImg"
                :close-on-click-modal="false"
                width="500px"
                center
                :before-close="closeDialog"
        >
            <span slot="title" class="dialog-title">二维码</span>

            <div class="editFlForm">
                <el-image class="share-img" :src="shareImg"></el-image>
            </div>
            <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="showImg = false">确定</el-button>
        <el-button @click="showImg = false">取消</el-button>
      </span>
        </el-dialog>
        <el-dialog
                :visible="editfl"
                :close-on-click-modal="false"
                width="500px"
                center
                append-to-body
                :before-close="closeDialog"
        >
      <span slot="title" class="dialog-title"
      >设置{{
          fl_type === 0 ? "" : fl_type === 1 ? $getTitleByType('会议', cid) : "专项"
          }}管理费率(%)</span
      >
            <div class="editFlForm">
                <div class="dialog-container" v-if="(fl_rate * 100) <= 0">
                    当前未设置{{
                    fl_type === 0 ? "" : fl_type === 1 ? "会议" : "专项"
                    }}管理费率
                </div>
                <div class="dialog-container" v-else>
                    当前{{
                    fl_type === 0 ? "" : fl_type === 1 ? $getTitleByType('会议', cid) : "专项"
                    }}管理费率<span>{{ (fl_rate * 100).toFixed(0) }}%</span>
                </div>

                <div class="dialog-container flex-center">
                    <div>
                        设置新{{
                        fl_type === 0 ? "" : fl_type === 1 ? $getTitleByType('会议', cid) : "专项"
                        }}管理费率
                    </div>
                    <el-input
                            v-model="flValue"
                            type="number"
                            :maxlength="4"
                            :max="100"
                            placeholder="请输入"
                            @input="proving"
                            @keyup.native="proving"
                    ></el-input>
                    <div>%</div>
                </div>
                <div class="dialog-tip">
                    1.此费率调整表X1和2的{{$getTitleByType('会议', cid)}}金额部分。<br/>
                    2.根据赞助协议中选择的支持类别，赞助时无法填写15%以下数值，捐赠时无法填写12%以下的数值。如填写需点击下方申请按钮，通过后可设置
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
        <el-button
                type="primary"
                @click="applyRate"
        >申请优惠率</el-button
        >
        <!--<el-button type="primary" disabled v-if="fl_state > 1">{{-->
            <!--fl_state | state-->
            <!--}}</el-button>-->
        <el-button type="primary" @click="subFl">确定</el-button>
        <el-button @click="closeDialog">取消</el-button>
      </span>
            <el-dialog
                :visible="applyRateDialog"
                :close-on-click-modal="false"
                width="500px"
                center
                append-to-body
                :before-close="closeDialog">
                <span slot="title" class="dialog-title">费率申请</span>
                <div class="dialog-container flex-center">
                    <div class="flex-shrink margin-right-xs">申请单位</div>
                    <el-input
                        style="width: 100%;"
                        v-model="flCompany"
                        placeholder="请输入"
                    ></el-input>
                </div>
                <div class="flex flex-center margin-top-sm">
                    <div  class="flex-shrink margin-right-xs">申请理由</div>
                    <el-input
                        v-model="flReason"
                        placeholder="请输入"
                    ></el-input>
                </div>
                <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="applyDiscounts">确定</el-button>
        <el-button @click="closeDialog">取消</el-button>
      </span>
            </el-dialog>
        </el-dialog>

        <el-dialog
                :visible="editHt2Time"
                :close-on-click-modal="false"
                width="500px"
                center
                :before-close="closeDialog"
        >
            <span slot="title" class="dialog-title">设置合同已回时间</span>
            <div class="editFlForm">
                <el-date-picker
                        v-model="hetong_time"
                        type="datetime"
                        placeholder="选择日期"
                >
                </el-date-picker>
            </div>
            <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="setHetongDate">确定</el-button>
        <el-button @click="closeDialog">取消</el-button>
      </span>
        </el-dialog>
    </div>
</template>

<script>
    import config from "../../config/index";
    import ElImage from "../../../node_modules/element-ui/packages/image/src/main.vue";
    import ElDialog from "../../../node_modules/element-ui/packages/dialog/src/component.vue";
    import {downloadFile} from "../../tools";

    export default {
        components: {
            ElDialog,
            ElImage,
        },
        name: "list",
        data() {
            return {
                table: 1,
                id: "",
                uid:'',
                isCWLD:false,
                isAdmin:false,
                tableData: [],
                tableData2: [],
                rate: "",
                total: [
                    {
                        name: "拟定协议金额（万元）",
                        num: 0,
                        name2: "拟定会议基金金额（万元）",
                        num2: 0,
                        name3: "拟定专项基金金额（万元）",
                        num3: 0,
                        isEdit: false,
                    },
                    {
                        name: "协议签订金额（万元）",
                        num: 0,
                        name2: "会议基金签订金额（万元）",
                        num2: 0,
                        name3: "专项基金签订金额（万元）",
                        num3: 0,
                        isEdit: false,
                    },
                    {
                        name: "协议到款金额（万元）",
                        num: 0,
                        name2: "会议基金到款金额（万元）",
                        num2: 0,
                        name3: "专项基金到款金额（万元）",
                        num3: 0,
                        isEdit: false,
                    },
                    {
                        name: "协议可用金额（万元）",
                        num: 0,
                        name2: "会议基金可用金额（万元）",
                        num2: 0,
                        name3: "专项基金可用金额（万元）",
                        num3: 0,
                        isEdit: true,
                    },
                ],
                total2: [
                    {
                        name: "付款总计（万元）",
                        num: 0,
                        color: " #666666",
                    },
                    {
                        name: "协议可用余额（万元）",
                        num: 0,
                        color: " #3b77e7",
                    },
                    {
                        name: "会议基金余额（万元）",
                        num: 0,
                        color: " #5daf34",
                    },
                    {
                        name: "专项基金余额（万元）",
                        num: 0,
                        color: " #df5000",
                    },
                ],
                total3: [
                    {
                        name: "付款总计（万元）",
                        num: 0,
                    },
                    {
                        name: "会议基金余额（万元）",
                        num: 0,
                    },
                ],
                flValue: "",
                flCompany: "",
                flReason: "",
                cur_id: -1,
                fl_type: 0,
                fl_state: 0,
                fl_rate: 0,
                editfl: false,
                setItem: null,
                editZanzhu: false,
                applyRateDialog: false,
                invoice_time: "",
                editHtTime: false,
                setHtId: "",
                hetong_time: "",
                editHt2Time: false,
                zzInfo: {
                    num1: "",
                    num2: "",
                    num3: "",
                },
                editCompany: false,
                cInfo: {
                    num1: "",
                    num2: "",
                    num3: "",
                },
                requestState: false,
                isPublish: false,
                shareImg: "",
                showImg: false,
                table_remark: "",
                societyOptions: [],       //  学会
                cid:0,
                p_name:''
            };
        },
        created() {
            this.id = this.$route.query.id;
            this.uid =this.$route.query.uid
            this.isAdmin = this.user.mid === Number(this.$route.query.uid)
            this.isCWLD  = this.user.mid === config.zhennggehong
            this.$emit("setIndex", [1, 1]);
            this.init();
            this.getSociety();
            this.getRemarks();

        },
        computed: {
            user() {
                return this.$store.state.user;
            },
        },
        methods: {
            proving() {
                // this.flValue = this.flValue.replace(/[^0-9]/g, "");
                // this.flValue = this.flValue.replace(".", "");
            },
            getSociety() {
                // 获取学会列表
                this.$api.apiContent.getSociety({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    sign: this.$tools.SHA256({
                        token: this.$store.state.user.token,
                        timestamp: this.$tools.requestTime(),
                    }, this.$store.state.user.key)
                }).then(res => {
                    this.societyOptions = res.data
                }).catch(() => {
                    this.societyOptions = []
                })
            },
            revokeItem(id) {
                let that = this;
                that
                    .$confirm("撤销后将撤销相关审批，您确定要撤销吗", "温馨提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                    })
                    .then(() => {
                        that.$api.apiContent
                            .withdraw({
                                token: that.$store.state.user.token,
                                timestamp: that.$tools.requestTime(),
                                id: id,
                                sign: that.$tools.SHA256(
                                    {
                                        token: that.$store.state.user.token,
                                        timestamp: that.$tools.requestTime(),
                                        id: id,
                                    },
                                    that.$store.state.user.key
                                ),
                            })
                            .then(() => {
                                that.$message.success("撤销成功");
                                that.init();
                            })
                            .catch((e) => {
                                that.$message.error(e.msg);
                            });
                    })
                    .catch(() => {
                        this.setItem = null;
                    });
            },
            revokeXYItem(id) {
                let that = this;
                that
                    .$confirm("撤销后将撤销相关审批，您确定要撤销吗", "温馨提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                    })
                    .then(() => {
                        that.$api.apiContent
                            .withdrawTablex1({
                                token: that.$store.state.user.token,
                                timestamp: that.$tools.requestTime(),
                                id: id,
                                sign: that.$tools.SHA256(
                                    {
                                        token: that.$store.state.user.token,
                                        timestamp: that.$tools.requestTime(),
                                        id: id,
                                    },
                                    that.$store.state.user.key
                                ),
                            })
                            .then(() => {
                                that.$message.success("撤销成功");
                                that.init();
                            })
                            .catch((e) => {
                                that.$message.error(e.msg);
                            });
                    })
                    .catch(() => {
                        this.setItem = null;
                    });
            },
            //  删除
            deleteItem(id) {
                let that = this;
                that
                    .$confirm("您确定要删除当前赞助信息吗", "温馨提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                    })
                    .then(() => {
                        that.$api.apiContent
                            .delSponsor({
                                token: that.$store.state.user.token,
                                timestamp: that.$tools.requestTime(),
                                id: id,
                                sign: that.$tools.SHA256(
                                    {
                                        token: that.$store.state.user.token,
                                        timestamp: that.$tools.requestTime(),
                                        id: id,
                                    },
                                    that.$store.state.user.key
                                ),
                            })
                            .then(() => {
                                that.$message.success("删除成功");
                                that.init();
                            })
                            .catch((e) => {
                                that.$message.error(e.msg);
                            });
                    })
                    .catch(() => {
                        this.setItem = null;
                    });
            },//  删除
            deleteCompanyItem(id) {
                let that = this;
                that
                    .$confirm("您确定要删除当前供应商信息吗", "温馨提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                    })
                    .then(() => {
                        that.$api.apiContent
                            .tablex2Del({
                                token: that.$store.state.user.token,
                                timestamp: that.$tools.requestTime(),
                                id: id,
                                sign: that.$tools.SHA256(
                                    {
                                        token: that.$store.state.user.token,
                                        timestamp: that.$tools.requestTime(),
                                        id: id,
                                    },
                                    that.$store.state.user.key
                                ),
                            })
                            .then(() => {
                                that.$message.success("删除成功");
                                that.init();
                            })
                            .catch((e) => {
                                that.$message.error(e.msg);
                            });
                    })
                    .catch(() => {
                        this.setItem = null;
                    });
            },
            setRemarks() {
                if (!this.table_remark) {
                    this.$message.error("请输入备注");
                    return;
                }
                let that = this;
                that.$api.apiContent
                    .setRemarks({
                        token: that.$store.state.user.token,
                        timestamp: that.$tools.requestTime(),
                        pid: this.id,
                        remarks: that.table_remark,
                        sign: that.$tools.SHA256(
                            {
                                token: that.$store.state.user.token,
                                timestamp: that.$tools.requestTime(),
                                pid: this.id,
                                remarks: that.table_remark,
                            },
                            that.$store.state.user.key
                        ),
                    })
                    .then(() => {
                        that.$message.success("保存成功");
                        that.getRemarks()
                    })
                    .catch((e) => {
                        that.$message.error(e.msg);
                    });
            },
            getRemarks() {
                let that = this;
                that.$api.apiContent
                    .getRemarks({
                        token: that.$store.state.user.token,
                        timestamp: that.$tools.requestTime(),
                        pid: this.id,
                        sign: that.$tools.SHA256(
                            {
                                token: that.$store.state.user.token,
                                timestamp: that.$tools.requestTime(),
                                pid: this.id,
                            },
                            that.$store.state.user.key
                        ),
                    })
                    .then(res=> {
                        this.table_remark = res.data.remarks
                    })
                    .catch((e) => {
                        that.$message.error(e.msg);
                    });
            },
            setSponsorState(id) {
                let that = this;
                that
                    .$confirm("您确定要设置当前协议状态为已回吗", "温馨提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                    })
                    .then(() => {
                        that.$api.apiContent
                            .setSponsorState({
                                token: that.$store.state.user.token,
                                timestamp: that.$tools.requestTime(),
                                id: id,
                                sign: that.$tools.SHA256(
                                    {
                                        token: that.$store.state.user.token,
                                        timestamp: that.$tools.requestTime(),
                                        id: id,
                                    },
                                    that.$store.state.user.key
                                ),
                            })
                            .then(() => {
                                that.init();
                            })
                            .catch((e) => {
                                that.$message.error(e.msg);
                            });
                    })
                    .catch(() => {
                        this.setItem = null;
                    });
            },
            setHetongDate() {
                let that = this;
                if (!this.hetong_time) {
                    this.$message.error("请选择时间");
                    return;
                }
                that.$api.apiContent
                    .setContractState({
                        token: that.$store.state.user.token,
                        timestamp: that.$tools.requestTime(),
                        id: this.setHtId,
                        contract_time: that.$tools.ZHDate(new Date(that.hetong_time), 6),
                        sign: that.$tools.SHA256(
                            {
                                token: that.$store.state.user.token,
                                timestamp: that.$tools.requestTime(),
                                id: this.setHtId,
                                contract_time: that.$tools.ZHDate(new Date(that.hetong_time), 6),
                            },
                            that.$store.state.user.key
                        ),
                    })
                    .then(() => {
                        that.closeDialog();
                        that.init();
                    })
                    .catch((e) => {
                        that.$message.error(e.msg);
                    });
            },
            setContractState(id) {
                this.setHtId = id;
                this.editHt2Time = true;
                this.hetong_time = new Date();
            },
            setInvoiceTime(id) {
                this.editHtTime = true;
                this.invoice_time = new Date();
                this.cur_id = id;
            },
            setInvoiceDate() {
                let that = this;
                if (!this.invoice_time) {
                    this.$message.error("请选择时间");
                    return;
                }
                that.$api.apiContent
                    .setInvoiceDate({
                        token: that.$store.state.user.token,
                        timestamp: that.$tools.requestTime(),
                        id: this.cur_id,
                        invoice_time: that.$tools.ZHDate(new Date(that.invoice_time), 6),
                        sign: that.$tools.SHA256(
                            {
                                token: that.$store.state.user.token,
                                timestamp: that.$tools.requestTime(),
                                id: this.cur_id,
                                invoice_time: that.$tools.ZHDate(new Date(that.invoice_time), 6),
                            },
                            that.$store.state.user.key
                        ),
                    })
                    .then(() => {
                        that.closeDialog();
                        that.init();
                    })
                    .catch((e) => {
                        that.$message.error(e.msg);
                    });
            },
            //  设置费率
            setFl(type, id, rate, rate_state) {
                this.cur_id = id;
                this.fl_type = type;
                this.fl_rate = rate;
                this.fl_state = rate_state;
                this.editfl = true;
            },
            applyRate() {
                if (!this.flValue) {
                    this.$message.error("请输入费率");
                    return;
                }
                this.applyRateDialog = true
            },
            applyDiscounts() {
                if (!this.flCompany) {
                    this.$message.error("请输申请单位");
                    return;
                }
                if (!this.flReason) {
                    this.$message.error("请输入申请理由");
                    return;
                }
                let that = this;
                if (this.fl_type === 0) {
                    that.$api.apiContent
                        .applyProject({
                            token: that.$store.state.user.token,
                            timestamp: that.$tools.requestTime(),
                            pid: that.cur_id,
                            rate: that.flValue,
                            company: that.flCompany,
                            reason: that.flReason,
                            sign_code: "",
                            sign: that.$tools.SHA256(
                                {
                                    token: that.$store.state.user.token,
                                    timestamp: that.$tools.requestTime(),
                                    pid: that.cur_id,
                                    rate: that.flValue,
                                    company: that.flCompany,
                                    reason: that.flReason,
                                    sign_code: "",
                                },
                                that.$store.state.user.key
                            ),
                        })
                        .then((res) => {
                            this.init();
                            that.closeDialog();
                            this.$router.push({
                                name: "rate",
                                query: {id: res.data.id, type: 1, classify: 10},
                            });
                        })
                        .catch((e) => {
                            that.$message.error(e.msg);
                        });
                } else if (this.fl_type === 1) {
                    that.$api.apiContent
                        .applyMeeting({
                            token: that.$store.state.user.token,
                            timestamp: that.$tools.requestTime(),
                            id: that.cur_id,
                            rate: that.flValue,
                            company: that.flCompany,
                            reason: that.flReason,
                            sign_code: "",
                            sign: that.$tools.SHA256(
                                {
                                    token: that.$store.state.user.token,
                                    timestamp: that.$tools.requestTime(),
                                    id: that.cur_id,
                                    rate: that.flValue,
                                    company: that.flCompany,
                                    reason: that.flReason,
                                    sign_code: "",
                                },
                                that.$store.state.user.key
                            ),
                        })
                        .then((res) => {
                            this.init();
                            that.closeDialog();
                            this.$router.push({
                                name: "rate",
                                query: {id: res.data.id, type: 1, classify: 11},
                            });
                        })
                        .catch((e) => {
                            that.$message.error(e.msg);
                        });
                } else {
                    that.$api.apiContent
                        .applySelf({
                            token: that.$store.state.user.token,
                            timestamp: that.$tools.requestTime(),
                            id: that.cur_id,
                            rate: that.flValue,
                            company: that.flCompany,
                            reason: that.flReason,
                            sign_code: "",
                            sign: that.$tools.SHA256(
                                {
                                    token: that.$store.state.user.token,
                                    timestamp: that.$tools.requestTime(),
                                    id: that.cur_id,
                                    rate: that.flValue,
                                    company: that.flCompany,
                                    reason: that.flReason,
                                    sign_code: "",
                                },
                                that.$store.state.user.key
                            ),
                        })
                        .then((res) => {
                            this.init();
                            that.closeDialog();
                            this.$router.push({
                                name: "rate",
                                query: {id: res.data.id, type: 1, classify: 12},
                            });
                        })
                        .catch((e) => {
                            that.$message.error(e.msg);
                        });
                }
            },
            subFl() {
                if (!this.flValue) {
                    this.$message.error("请输入费率");
                    return;
                }
                let that = this;
                if (this.fl_type === 0) {
                    that.$api.apiContent
                        .setProjectRate({
                            token: that.$store.state.user.token,
                            timestamp: that.$tools.requestTime(),
                            id: that.cur_id,
                            rate: that.flValue,
                            sign: that.$tools.SHA256(
                                {
                                    token: that.$store.state.user.token,
                                    timestamp: that.$tools.requestTime(),
                                    id: that.cur_id,
                                    rate: that.flValue,
                                },
                                that.$store.state.user.key
                            ),
                        })
                        .then(() => {
                            that.closeDialog();
                            this.init();
                        })
                        .catch((e) => {
                            that.$message.error(e.msg);
                        });
                } else if (this.fl_type === 1) {
                    that.$api.apiContent
                        .setMeetingOnly({
                            token: that.$store.state.user.token,
                            timestamp: that.$tools.requestTime(),
                            id: that.cur_id,
                            rate: that.flValue,
                            sign: that.$tools.SHA256(
                                {
                                    token: that.$store.state.user.token,
                                    timestamp: that.$tools.requestTime(),
                                    id: that.cur_id,
                                    rate: that.flValue,
                                },
                                that.$store.state.user.key
                            ),
                        })
                        .then(() => {
                            that.closeDialog();
                            this.init();
                        })
                        .catch((e) => {
                            that.$message.error(e.msg);
                        });
                } else {
                    that.$api.apiContent
                        .setSelfOnly({
                            token: that.$store.state.user.token,
                            timestamp: that.$tools.requestTime(),
                            id: that.cur_id,
                            rate: that.flValue,
                            sign: that.$tools.SHA256(
                                {
                                    token: that.$store.state.user.token,
                                    timestamp: that.$tools.requestTime(),
                                    id: that.cur_id,
                                    rate: that.flValue,
                                },
                                that.$store.state.user.key
                            ),
                        })
                        .then(() => {
                            that.closeDialog();
                            this.init();
                        })
                        .catch((e) => {
                            that.$message.error(e.msg);
                        });
                }
            },
            //  赞助提交
            zzSubmit() {
                if (!this.zzInfo.num1) {
                    this.$message.error("请选择学会");
                    return;
                }
                if (!this.zzInfo.num2) {
                    this.$message.error(this.$refs.input.placeholder);
                    return;
                }
                if (!this.zzInfo.num3) {
                    this.$message.error("请输入备注");
                    return;
                }
                if (this.requestState) {
                    return;
                }
                this.requestState = true;
                if (!this.setItem) {
                    this.$api.apiContent
                        .inseSponsor({
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            pid: this.id,
                            society: this.zzInfo.num1,
                            amount_desc: this.zzInfo.num3,
                            meet_run: this.zzInfo.num2,
                            sign: this.$tools.SHA256(
                                {
                                    token: this.$store.state.user.token,
                                    timestamp: this.$tools.requestTime(),
                                    pid: this.id,
                                    society: this.zzInfo.num1,
                                    amount_desc: this.zzInfo.num3,
                                    meet_run: this.zzInfo.num2,
                                },
                                this.$store.state.user.key
                            ),
                        })
                        .then(() => {
                            this.$message.success("添加成功");
                            this.requestState = false;
                            this.init();
                            this.closeDialog();
                        })
                        .catch((e) => {
                            this.$message.error(e.msg);
                            this.requestState = false;
                        });
                } else {
                    this.$api.apiContent
                        .editSponsor({
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            id: this.setItem.id,
                            society: this.zzInfo.num1,
                            amount_desc: this.zzInfo.num3,
                            meet_run: this.zzInfo.num2,
                            sign: this.$tools.SHA256(
                                {
                                    token: this.$store.state.user.token,
                                    timestamp: this.$tools.requestTime(),
                                    id: this.setItem.id,
                                    society: this.zzInfo.num1,
                                    amount_desc: this.zzInfo.num3,
                                    meet_run: this.zzInfo.num2,
                                },
                                this.$store.state.user.key
                            ),
                        })
                        .then(() => {
                            this.$message.success("修改成功");
                            this.init();
                            this.closeDialog();
                            this.requestState = false;
                        })
                        .catch((e) => {
                            this.$message.error(e.msg);
                            this.requestState = false;
                        });
                }
            },
            //  供应商提交
            gySubmit() {
                if (!this.cInfo.num1) {
                    this.$message.error("请选择学会");
                    return;
                }
                if (!this.cInfo.num4) {
                    this.$message.error("请输入付款项目");
                    return;
                }
                if (!this.cInfo.num2) {
                    this.$message.error("请输入会议总金额");
                    return;
                }
                if (!this.cInfo.num3) {
                    this.$message.error("请输入备注");
                    return;
                }
                if (this.requestState) {
                    return;
                }
                this.requestState = true;
                if (!this.setItem) {
                    this.$api.apiContent
                        .tablex2Add({
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            pid: this.id,
                            sid: this.cInfo.num1,
                            payment_amount: this.cInfo.num2,
                            remark: this.cInfo.num3,
                            payment_project: this.cInfo.num4,
                            sign: this.$tools.SHA256(
                                {
                                    token: this.$store.state.user.token,
                                    timestamp: this.$tools.requestTime(),
                                    pid: this.id,
                                    sid: this.cInfo.num1,
                                    payment_amount: this.cInfo.num2,
                                    remark: this.cInfo.num3,
                                    payment_project: this.cInfo.num4,
                                },
                                this.$store.state.user.key
                            ),
                        })
                        .then(() => {
                            this.$message.success("添加成功");
                            this.requestState = false;
                            this.init();
                            this.closeDialog();
                        })
                        .catch((e) => {
                            this.$message.error(e.msg);
                            this.requestState = false;
                        });
                } else {
                    this.$api.apiContent
                        .tablex2Edit({
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            pid: this.id,
                            id: this.setItem.id,
                            sid: this.cInfo.num1,
                            payment_amount: this.cInfo.num2,
                            remark: this.cInfo.num3,
                            payment_project: this.cInfo.num4,
                            sign: this.$tools.SHA256(
                                {
                                    token: this.$store.state.user.token,
                                    timestamp: this.$tools.requestTime(),
                                    pid: this.id,
                                    id: this.setItem.id,
                                    sid: this.cInfo.num1,
                                    payment_amount: this.cInfo.num2,
                                    remark: this.cInfo.num3,
                                    payment_project: this.cInfo.num4,
                                },
                                this.$store.state.user.key
                            ),
                        })
                        .then(() => {
                            this.$message.success("修改成功");
                            this.init();
                            this.closeDialog();
                            this.requestState = false;
                        })
                        .catch((e) => {
                            this.$message.error(e.msg);
                            this.requestState = false;
                        });
                }
            },
            getShare() {
                this.$api.apiContent
                    .tablex1_excel({
                        token: this.$store.state.user.token,
                        timestamp: this.$tools.requestTime(),
                        id: this.id,
                        path:window.location.origin+'/pdf?id='+this.id,
                        sign: this.$tools.SHA256(
                            {
                                token: this.$store.state.user.token,
                                timestamp: this.$tools.requestTime(),
                                id: this.id,
                                path:window.location.origin+'/pdf?id='+this.id,
                            },
                            this.$store.state.user.key
                        ),
                    })
                    .then((res) => {
                        console.log(res);
                        this.shareImg = config.urlImgStr + res.data;
                        this.showImg = true;
                    })
                    .catch((e) => {
                        this.$message.error(e.msg);
                    });
            },
            downFile() {
                this.$api.apiContent
                    .tablex1_down({
                        token: this.$store.state.user.token,
                        timestamp: this.$tools.requestTime(),
                        id: this.id,
                        sign: this.$tools.SHA256(
                            {
                                token: this.$store.state.user.token,
                                timestamp: this.$tools.requestTime(),
                                id: this.id,
                            },
                            this.$store.state.user.key
                        ),
                    })
                    .then((res) => {
                        console.log(res);
                        downloadFile(res.data,`${this.p_name}赞助汇总表${new Date().getTime() }.xlsx`)
                        // window.open(config.urlImgStr + res.data);
                    })
                    .catch((e) => {
                        this.$message.error(e.msg);
                    });
            },
            //  显示弹框
            showDialog(obj) {
                this.editZanzhu = true;
                if (obj) {
                    this.setItem = obj;
                    this.zzInfo = {
                        num1: obj.sid,
                        num3: obj.amount_desc,
                        num2: obj.meet_run,
                    };
                } else {
                    this.setItem = "";
                    this.zzInfo = {
                        num2: "",
                        num3: "",
                        num1: "",
                    };
                }
            },
            //  显示弹框
            showCompanyDialog(obj) {
                this.editCompany = true;
                if (obj) {
                    this.setItem = obj;
                    this.cInfo = {
                        num1: obj.sid,
                        num2: obj.payment_amount,
                        num4: obj.payment_project,
                        num3: obj.remark,
                    };
                } else {
                    this.setItem = "";
                    this.cInfo = {
                        num2: "",
                        num3: "",
                        num4: "",
                        num1: "",
                    };
                }
            },
            //  关闭弹框
            closeDialog() {
                this.editZanzhu = false;
                this.editCompany = false;
                this.editHtTime = false;
                this.editHt2Time = false;
                this.applyRateDialog = false;
                this.editfl = false;
                this.setItem = null;
                this.showImg = false;
            },
            //  合并单元格
            objectSpanMethod({row, column, rowIndex, columnIndex}) {
                console.log(row, column);
                if (columnIndex === 0) {
                    if (rowIndex % 4 === 0) {
                        return {
                            rowspan: 4,
                            colspan: 1,
                        };
                    } else {
                        return {
                            rowspan: 0,
                            colspan: 0,
                        };
                    }
                }
            },
            //  页面加载时请求
            init() {
                this.$api.apiContent
                    .getTableX2({
                        token: this.$store.state.user.token,
                        timestamp: this.$tools.requestTime(),
                        id: this.id,
                        type: this.table === 1 ? 1 : 0,
                        sign: this.$tools.SHA256(
                            {
                                token: this.$store.state.user.token,
                                timestamp: this.$tools.requestTime(),
                                id: this.id,
                                type: this.table === 1 ? 1 : 0,
                            },
                            this.$store.state.user.key
                        ),
                    })
                    .then((res) => {
                        res.data.first_list.map((item) => {
                            item.agreement_amount = parseFloat(
                                Number(item.agreement_amount).toFixed(6)
                            );
                            item.agreement_metting = parseFloat(
                                Number(item.agreement_metting).toFixed(6)
                            );
                            item.agreement_run = parseFloat(
                                Number(item.agreement_run).toFixed(6)
                            );
                            item.agreement_run_amount = parseFloat(
                                Number(item.agreement_run_amount).toFixed(6)
                            );
                            item.agreement_self = parseFloat(
                                Number(item.agreement_self).toFixed(6)
                            );
                            item.amount = parseFloat(Number(item.amount).toFixed(6));
                            item.available_meeting_amount = parseFloat(
                                Number(item.available_meeting_amount).toFixed(6)
                            );
                            item.fukuan_amount = parseFloat(
                                Number(item.fukuan_amount).toFixed(6)
                            );
                            item.management_amount = parseFloat(
                                Number(item.management_amount).toFixed(6)
                            );
                            item.meet_run = parseFloat(Number(item.meet_run).toFixed(6));
                            item.received_amount = parseFloat(
                                Number(item.received_amount).toFixed(6)
                            );
                            item.received_metting = parseFloat(
                                Number(item.received_metting).toFixed(6)
                            );
                            item.received_self = parseFloat(
                                Number(item.received_self).toFixed(6)
                            );
                            item.sponsorship_amount = parseFloat(
                                Number(item.sponsorship_amount).toFixed(6)
                            );
                            item.sponsorship_metting = parseFloat(
                                Number(item.sponsorship_metting).toFixed(6)
                            );
                            item.sponsorship_self = parseFloat(
                                Number(item.sponsorship_self).toFixed(6)
                            );
                        });
                        let contract_amount_count = 0;
                        let contract_self_count = 0;
                        let contract_meeting_count = 0;
                        let payment_amount_count = 0;
                        let payment_self_count = 0;
                        let payment_meeting_count = 0;
                        res.data.second_list.map((item) => {
                            item.contract_amount = parseFloat(
                                Number(item.contract_amount).toFixed(6)
                            );
                            item.contract_meeting = parseFloat(
                                Number(item.contract_meeting).toFixed(6)
                            );
                            item.contract_self = parseFloat(
                                Number(item.contract_self).toFixed(6)
                            );
                            item.payment_amount = parseFloat(
                                Number(item.payment_amount).toFixed(6)
                            );
                            item.payment_meeting = parseFloat(
                                Number(item.payment_meeting).toFixed(6)
                            );
                            item.payment_self = parseFloat(
                                Number(item.payment_self).toFixed(6)
                            );
                            contract_amount_count = contract_amount_count + Number(item.contract_amount);
                            contract_self_count = contract_self_count + Number(item.contract_self);
                            contract_meeting_count = contract_meeting_count + Number(item.contract_meeting);
                            payment_amount_count = payment_amount_count + Number(item.payment_amount);
                            payment_self_count = payment_self_count + Number(item.payment_self);
                            payment_meeting_count = payment_meeting_count + Number(item.payment_meeting);
                        });
                        this.p_rate = res.data.rate;
                        this.tableData = res.data.first_list;
                        this.tableData2 = res.data.second_list;

                        this.total[0].num = parseFloat(res.data.total.sponsorship.toFixed(6));
                        this.total[0].num2 = parseFloat(
                            res.data.total.sponsorship_meet.toFixed(6)
                        );
                        this.total[0].num3 = parseFloat(
                            res.data.total.sponsorship_self.toFixed(6)
                        );

                        this.total[1].num = parseFloat(res.data.total.signing.toFixed(6));

                        this.total[1].num2 = parseFloat(
                            res.data.total.signing_meet.toFixed(6)
                        );
                        this.total[1].num3 = parseFloat(
                            res.data.total.signing_self.toFixed(4)
                        );

                        this.total[2].num = parseFloat(res.data.total.received.toFixed(6));
                        this.total[2].num2 = parseFloat(
                            res.data.total.received_meet.toFixed(6)
                        );
                        this.total[2].num3 = parseFloat(
                            res.data.total.received_self.toFixed(6)
                        );

                        this.total[3].num = parseFloat(res.data.total.available.toFixed(6));
                        this.total[3].num2 = parseFloat(
                            res.data.total.available_meet.toFixed(6)
                        );
                        this.total[3].num3 = parseFloat(
                            res.data.total.available_self.toFixed(6)
                        );

                        this.total3[0].num = parseFloat(
                            res.data.total.meeting_payment.toFixed(6)
                        );
                        this.total3[1].num = parseFloat(
                            (
                                res.data.total.available_meet - res.data.total.meeting_payment
                            ).toFixed(6)
                        );
                        this.total2[0].num = parseFloat(res.data.total.payment.toFixed(6));
                        this.total2[1].num = parseFloat(
                            (res.data.total.available - payment_amount_count).toFixed(6)
                            // (contract_amount_count - payment_amount_count).toFixed(6)
                            // (this.total[3].num - payment_amount_count).toFixed(6)
                        );

                        this.total2[2].num = parseFloat(
                            (res.data.total.available_meet - payment_meeting_count).toFixed(6)
                            // (contract_meeting_count - payment_meeting_count).toFixed(6)
                                //( this.total[3].num2 - payment_meeting_count).toFixed(6)
                        );
                        this.total2[3].num = parseFloat(
                            (res.data.total.available_self - payment_self_count).toFixed(6)
                            // (contract_self_count - payment_self_count).toFixed(6)
                            // (this.total[3].num3 - payment_self_count).toFixed(6)
                        );
                        this.cid = res.data.project_info.cid
                        this.p_name = res.data.project_info.name
                        this.setTotalLabel()
                    })
                    .catch(() => {
                        this.tableData = [];
                        this.tableData2 = [];

                    });
            },
            init1() {
                this.$api.apiContent
                    .getRuTableX({
                        token: this.$store.state.user.token,
                        timestamp: this.$tools.requestTime(),
                        pid: this.id,
                        sid: this.table === 3 ? 1 : 2,
                        sign: this.$tools.SHA256(
                            {
                                token: this.$store.state.user.token,
                                timestamp: this.$tools.requestTime(),
                                pid: this.id,
                                sid: this.table === 3 ? 1 : 2,
                            },
                            this.$store.state.user.key
                        ),
                    })
                    .then((res) => {
                        res.data.first_list.map((item) => {
                            item.agreement_amount = parseFloat(
                                Number(item.agreement_amount).toFixed(6)
                            );
                            item.agreement_metting = parseFloat(
                                Number(item.agreement_metting).toFixed(6)
                            );
                            item.agreement_run = parseFloat(
                                Number(item.agreement_run).toFixed(6)
                            );
                            item.agreement_run_amount = parseFloat(
                                Number(item.agreement_run_amount).toFixed(6)
                            );
                            item.agreement_self = parseFloat(
                                Number(item.agreement_self).toFixed(6)
                            );
                            item.amount = parseFloat(Number(item.amount).toFixed(6));
                            item.available_meeting_amount = parseFloat(
                                Number(item.available_meeting_amount).toFixed(6)
                            );
                            item.fukuan_amount = parseFloat(
                                Number(item.fukuan_amount).toFixed(6)
                            );
                            item.management_amount = parseFloat(
                                Number(item.management_amount).toFixed(6)
                            );
                            item.meet_run = parseFloat(Number(item.meet_run).toFixed(6));
                            item.received_amount = parseFloat(
                                Number(item.received_amount).toFixed(6)
                            );
                            item.received_metting = parseFloat(
                                Number(item.received_metting).toFixed(6)
                            );
                            item.received_self = parseFloat(
                                Number(item.received_self).toFixed(6)
                            );
                            item.sponsorship_amount = parseFloat(
                                Number(item.sponsorship_amount).toFixed(6)
                            );
                            item.sponsorship_metting = parseFloat(
                                Number(item.sponsorship_metting).toFixed(6)
                            );
                            item.sponsorship_self = parseFloat(
                                Number(item.sponsorship_self).toFixed(6)
                            );
                        });
                        let payment_amount_count = 0;
                        let payment_self_count = 0;
                        res.data.second_list.map((item) => {
                            item.contract_amount = parseFloat(
                                Number(item.contract_amount).toFixed(6)
                            );
                            item.contract_meeting = parseFloat(
                                Number(item.contract_meeting).toFixed(6)
                            );
                            item.contract_self = parseFloat(
                                Number(item.contract_self).toFixed(6)
                            );
                            item.payment_amount = parseFloat(
                                Number(item.payment_amount).toFixed(6)
                            );
                            item.payment_meeting = parseFloat(
                                Number(item.payment_meeting).toFixed(6)
                            );
                            item.payment_self = parseFloat(
                                Number(item.payment_self).toFixed(6)
                            );
                            payment_amount_count = payment_amount_count + Number(item.payment_amount);
                            payment_self_count = payment_self_count + Number(item.payment_self);
                        });
                        this.tableData = res.data.first_list;
                        this.tableData2 = res.data.second_list;
                        this.total[0].num = parseFloat(res.data.total.sponsorship.toFixed(6));
                        this.total[0].num2 = parseFloat(
                            res.data.total.sponsorship_meet.toFixed(6)
                        );
                        this.total[0].num3 = parseFloat(
                            res.data.total.sponsorship_self.toFixed(6)
                        );

                        this.total[1].num = parseFloat(res.data.total.signing.toFixed(6));
                        this.total[1].num2 = parseFloat(
                            res.data.total.signing_meet.toFixed(6)
                        );
                        this.total[1].num3 = parseFloat(
                            res.data.total.signing_self.toFixed(4)
                        );

                        this.total[2].num = parseFloat(res.data.total.received.toFixed(6));
                        this.total[2].num2 = parseFloat(
                            res.data.total.received_meet.toFixed(6)
                        );
                        this.total[2].num3 = parseFloat(
                            res.data.total.received_self.toFixed(6)
                        );

                        this.total[3].num = parseFloat(res.data.total.available.toFixed(6));
                        this.total[3].num2 = parseFloat(
                            res.data.total.available_meet.toFixed(6)
                        );
                        this.total[3].num3 = parseFloat(
                            res.data.total.available_self.toFixed(6)
                        );

                        this.total3[0].num = parseFloat(
                            res.data.total.meeting_payment.toFixed(6)
                        );
                        this.total3[1].num = parseFloat(
                            (
                                res.data.total.available_meet - res.data.total.meeting_payment
                            ).toFixed(6)
                        );

                        this.total2[0].num = parseFloat(res.data.total.payment.toFixed(6));
                        this.total2[1].num = parseFloat(
                            (res.data.total.available - payment_amount_count).toFixed(6)
                        );
                        this.total2[2].num = parseFloat(
                            (
                                res.data.total.available_meet - res.data.total.meeting_payment
                            ).toFixed(6)
                        );
                        this.total2[3].num = parseFloat(
                            (res.data.total.available_self - payment_self_count).toFixed(6)
                        );
                    })
                    .catch(() => {
                        this.tableData = [];
                        this.tableData2 = [];
                    });
            },
            init2() {
                this.$api.apiContent
                    .tablex2xStatistics({
                        token: this.$store.state.user.token,
                        timestamp: this.$tools.requestTime(),
                        id: this.id,
                        type: this.table,
                        sign: this.$tools.SHA256(
                            {
                                token: this.$store.state.user.token,
                                timestamp: this.$tools.requestTime(),
                                id: this.id,
                                type: this.table,
                            },
                            this.$store.state.user.key
                        ),
                    })
                    .then((res) => {
                        res.data.list.map((item) => {
                            item.hetong_time = this.$tools.ZHDate(
                                new Date(item.hetong_time * 1000)
                            );
                            item.fapiao = this.$tools.ZHDate(new Date(item.fapiao * 1000));
                        });
                        this.tableData2 = res.data.list;
                        this.total2[0].num = parseFloat(res.data.fukuan_amount.toFixed(6));
                        this.total2[1].num = parseFloat(
                            (this.total[3].num * 1 - res.data.fukuan_amount * 1).toFixed(6)
                        );
                    })
                    .catch(() => {
                        this.tableData2 = [];
                    });
            },
            //  返回
            back() {
                this.$router.go(-1);
            },
            setTotalLabel() {
                this.total[0].name2 = `拟定${this.$tools.getTitleByType('会议',this.cid)}基金金额（万元）`
                this.total[1].name2 = `${this.$tools.getTitleByType('会议',this.cid)}基金签订金额（万元）`
                this.total[2].name2 = `${this.$tools.getTitleByType('会议',this.cid)}基金到款金额（万元）`
                this.total[3].name2 = `${this.$tools.getTitleByType('会议',this.cid)}基金可用金额（万元）`
                this.total3[1].name = `${this.$tools.getTitleByType('会议',this.cid)}基金余额（万元）`
                this.total2[2].name = `${this.$tools.getTitleByType('会议',this.cid)}基金余额（万元）`
            }
        },
        filters: {
            state(type) {
                switch (Number(type)) {
                    case 0:
                        return "未申请";
                    case 1:
                        return "已申请 ";
                    case 2:
                        return "已驳回";
                    case 3:
                        return "已通过";
                    default:
                        return "已通过";
                }
            },
        },
        watch: {
            table() {
                if (this.table > 2) {
                    this.init1();
                } else {
                    this.init();
                }

            },
        },
    };
</script>

<style scoped lang="scss">
    .page-box {
        .page-title {
            height: 40px;
            margin-bottom: 10px;

            span {
                font-size: 18px;
                line-height: 36px;
                color: #999999;

                b {
                    color: #333;
                    font-weight: normal;
                }
            }

            div {
                color: #3b77e7;
                font-size: 20px;
                cursor: pointer;

                i {
                    display: block;
                    width: 24px;
                    height: 24px;
                    text-align: center;
                    line-height: 24px;
                    margin-right: 5px;
                    border-radius: 100%;
                    color: #fff;
                    font-size: 14px;
                    background-color: #3b77e7;
                }
            }
        }

        .project-info {
            width: 100%;
            background-color: #ffffff;
            border-radius: 6px;
            overflow: hidden;

            .project-condition {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                padding: 24px 28px 16px;
                border-bottom: 2px solid #f5f5f5;

                .title-tab {
                    .active {
                        background: #3b77e7;
                        color: #fff;
                    }

                    .settlement {
                        margin-left: 10px;
                    }
                }

                .el-select {
                    margin-right: 20px;
                }

                .el-input {
                    margin: 0 20px;
                    width: 268px;
                }
            }

            .project-list {
                padding: 0px 28px 30px;
                min-height: 700px;

                .remerk-container {
                    margin-top: 30px;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;

                    .remerk-content {
                        margin-bottom: 30px;
                    }
                }

                .share-img {
                    width: 200px;
                    height: 200px;
                }

                h3 {
                    text-align: center;
                    line-height: 40px;
                    position: relative;
                    margin: 30px 0;
                    font-size: 28px;

                    div {
                        position: absolute;
                        top: 0;
                        right: 0;
                        display: flex;
                        align-items: center;
                    }
                }

                .jiange {
                    height: 30px;
                }

                .table-id {
                    text-align: center;
                    font-size: 16px;
                    color: #666666;
                }

                .table-item {
                    text-align: center;
                    font-size: 16px;
                    color: #666666;

                    .date {
                        width: 130px;
                        height: 45px;
                        border: 1px solid #f1f1f1;
                        line-height: 45px;
                    }
                }

                .table-operate {
                    display: flex;
                    flex-direction: column;
                    text-align: center;
                    font-size: 16px;
                    color: #666666;

                    .el-button {
                        margin-left: 0px;
                        font-size: 16px;
                    }
                }

                .page-all {
                    text-align: center;
                    padding: 30px 0 0;
                }
            }
        }
    }

    .dialog-title {
        font-size: 26px;
        display: block;
        line-height: 30px;
        margin-top: 20px;
    }

    .editForm {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;

        .dialog-container {
            width: 100%;
            padding: 0 20px;
            margin-bottom: 20px;
            font-size: 20px;
            color: #333333;

            &.active {
                width: 100%;

                .el-textarea {
                    flex: 1;
                    margin: 0 10px;
                }
            }

            div {
                width: 120px;
                flex-shrink: 0;
            }

            .el-input,
            .el-select,
            .el-radio-group,
            .el-textarea,
            .el-textarea__inner {
                flex-grow: 1;
                margin: 0 10px;
                font-size: 16px;
            }
        }
    }

    .editFlForm {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;

        .dialog-tip {
            font-size: 16px;
            color: #333333;
        }

        .dialog-container {
            padding: 0 20px;
            margin-bottom: 20px;
            font-size: 20px;
            color: #333333;

            &.active {
                width: 100%;

                .el-textarea {
                    flex: 1;
                    margin: 0 10px;
                }
            }

            div {
                flex-shrink: 0;
                font-size: 20px;
                font-weight: bold;
            }

            .el-input,
            .el-radio-group {
                width: 80px;
                margin: 0 10px;
                font-size: 16px;
            }
        }
    }

    .dialog-footer {
        .el-button {
            width: 120px;
            font-size: 16px;
        }
    }
</style>
